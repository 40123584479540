<template>
  <b-container class="mt-4">
    <b-row>
      <b-col>
        <b-card
          bg-variant="light"
          title="500: Server Error"
          title-tag="h1"
        >
          <p>
            An unknown error has occurred. Please contact the application support team if the issue continues.
          </p>
          <p>
            {{ $route.params.message || `An unknown error occurred` }}
          </p>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: `ServerError`,
};
</script>
